@let _navigationMode = navigationMode();
@let _showBars = showBars();
<mat-sidenav-container [ngClass]="'container-navigation-' + _navigationMode">
  <mat-sidenav
    autoFocus="false"
    fixedInViewport="true"
    [attr.role]="_navigationMode === 'bar' ? 'dialog' : 'navigation'"
    [attr.old-navigation-mode]="oldNavigationMode$ | async"
    [mode]="_navigationMode === 'drawer' ? 'side' : 'over'"
    [(opened)]="openDrawer"
  >
    @defer (on viewport) {
      <app-navigation-drawer />
    } @placeholder (minimum 500ms) {
      <app-navigation-skeleton />
    }
  </mat-sidenav>
  <mat-sidenav-content [ngClass]="{ 'with-bars': _showBars === 'visible' }">
    <app-top-app-bar [@scrollUpAnimation]="!isScrolled() || _showBars" />
    <main
      [@contextChange]="o | state"
      [style.viewTransitionName]="viewTransitionName()"
    >
      <router-outlet #o="outlet" />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
@defer (when _navigationMode === 'bar') {
  @let _stable = stable();
  <app-navigation-bar
    [@scrollDownAnimation]="_stable && _navigationMode === 'bar' && _showBars ? _showBars : hidden"
  />
}
