@let matchday = matchday$ | async; @let number = matchday?.number ?? 0; @if (number > 1) { @let
bestPlayers = bestPlayers$ | async;
<div
  class="grid grid-cols-1 grid-rows-1 items-start gap-2 sm:grid-cols-2 lg:grid-cols-4"
  @cardCreationAnimation
>
  @for (role of roles; track role.id) { @let bestPlayer = bestPlayers?.get(role);
  <mat-card appearance="outlined">
    <a [routerLink]="['/players', bestPlayer?.[0]?.player_id]">
      <app-player-image
        inCard
        matRipple
        mat-card-image
        [member]="bestPlayer?.[0]"
      />
    </a>
    @if (!bestPlayer) {
    <mat-progress-bar mode="indeterminate" />
    }
    <mat-card-header>
      <mat-card-title>
        @if (bestPlayer?.[0]) {
        <span class="mat-text-primary"> {{bestPlayer![0]!.ratings[0]?.points | number}} </span>
        {{bestPlayer![0]!.player.full_name}} } @else {
        <span class="mat-text-primary">-</span>
        }
      </mat-card-title>
      <mat-card-subtitle>{{role.singolar}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <app-best-players-list [best_players]="(bestPlayer ?? []) | slice: 1" />
    </mat-card-content>
  </mat-card>
  }
</div>
} @else { @defer(when number <= 1) {
<app-mat-empty-state
  description="Buona asta!"
  icon="wb_sunny"
  label="La stagione non è ancora iniziata"
  rounded
/>
} }
